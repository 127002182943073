<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="현장 산업위생 일지 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  ref="appr-btn"
                  name="appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="approvalJournal"
                  @callbackApprAction="approvalJournalCallback"
                  @requestAfterAction="getDetail"
                />
                <!-- 삭제 -->
                <c-btn 
                  v-if="isOld && editable && !disabled" 
                  label="LBLREMOVE" 
                  icon="delete_forever" 
                  @btnClicked="remove" />
                <!-- 저장 -->
                <c-btn
                  v-show="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="journal"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :editable="editable" 
                  :disabled="disabled"
                  type="edit" 
                  name="plantCd" 
                  v-model="journal.plantCd"
                  @datachange="datachange" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="점검일"
                  type="date"
                  name="hygieneJournalCheckDate"
                  v-model="journal.hygieneJournalCheckDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="점검명"
                  name="indHygieneJournalName"
                  v-model="journal.indHygieneJournalName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="현장"
                  name="indHygieneJournalLocation"
                  v-model="journal.indHygieneJournalLocation"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :required="true"
                  :editable="editable"
                  :disabled="disabled"
                  :data="journal"
                  :plantCd="journal.plantCd"
                  type="dept_user"
                  label="점검자"
                  name="checkUserId"
                  v-model="journal.checkUserId" />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 항목별 결과 목록 -->
          <!-- 항목별 결과 추가하세요. -->
          <c-table
            ref="grid"
            title="LBL00001613"
            :columns="grid.columns"
            :gridHeight="gridHeight"
            :data="journal.results"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :noDataLabel="$message('MSG0000752')"
            :hideBottom="true"
            :editable="editable && !disabled"
            rowKey="hygieneJournalResultId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <!-- 추가 -->
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="LBLADD"
                  icon="add"
                  @btnClicked="addResult"
                />
                <!-- 제외 -->
                <c-btn
                  v-if="editable && journal.results.length > 0 && !disabled"
                  :showLoading="false"
                  label="LBLEXCEPT"
                  icon="remove"
                  @btnClicked="removeResult"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'journal-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        indHygieneJournalId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            required: true,
            name: 'siteHygieneTypeCd',
            field: 'siteHygieneTypeCd',
            label: '구분',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            codeGroupCd: 'SITE_HYGIENE_TYPE_CD',
            setHeader: true,
            sortable: false,
          },
          {
            required: true,
            name: 'hygieneItemName',
            field: 'hygieneItemName',
            label: '점검항목',
            align: 'left',
            type: 'textarea',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'checkContents',
            field: 'checkContents',
            label: '점검결과',
            align: 'left',
            type: 'textarea',
            style: 'width:300px',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '관련 파일첨부',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'SITE_IND_HYGIENE_JOURNAL',
            keyText: 'hygieneJournalResultId',
            sortable: false,
          },
        ],
        height: "360px",
        data: [],
      },
      journal: {
        indHygieneJournalId: '',
        plantCd: '',
        indHygieneJournalName: '',
        indHygieneJournalLocation: '',
        hygieneJournalCheckDate: '',
        checkUserId: '',
        regUserId: '',
        chgUserId: '',
        results: [],
        deleteResults: [],
        sysApprovalRequestId: '',
      },
      editable: true,
      saveType: 'POST',
      resultItemUrl: '',
      updateUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      isApproval: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.journal.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.journal.approvalStatusCd, 
        apprEditable: this.editable && this.isOld, // 결재버튼 활성화 기준
        param: this.journal, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000025', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          indHygieneJournalId: this.journal.indHygieneJournalId,
          isApprContent: true
        },
        approvalRequestName: '(' + this.journal.indHygieneJournalName + ') 현장 산업위생 일지', // 결재요청명 (문서 title)
        approvalConnId: this.journal.indHygieneJournalId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    gridHeight() {
      let height = this.contentHeight - 250;
      if (height < 600) {
        height = 600;
      }
      return String(height) + 'px';
    },
    isOld() {
      return Boolean(this.popupParam.indHygieneJournalId)
    },
    disabled() {
      return Boolean(this.journal.sysApprovalRequestId)
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.sih.check.get.url;
      this.resultItemUrl = selectConfig.hea.sih.item.list.url;
      this.saveUrl = transactionConfig.hea.sih.check.insert.url;
      this.updateUrl = transactionConfig.hea.sih.check.update.url;
      this.deleteUrl = transactionConfig.hea.sih.check.delete.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.indHygieneJournalId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.journal, _result.data);
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.journal.hygieneJournalCheckDate = this.$comm.getToday();
        this.journal.indHygieneJournalName = this.journal.hygieneJournalCheckDate + ' 현장 산업위생 일지'
        this.$http.url = this.resultItemUrl
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.$store.getters.user.plantCd,
          useFlag: 'Y',
        };
        this.$http.request((_result) => {
          this.$_.forEach(_result.data, _item => {
            this.journal.results.push({
              indHygieneJournalId: '',
              hygieneJournalResultId: uid(),
              siteHygieneTypeCd: _item.siteHygieneTypeCd,
              hygieneItemName: _item.hygieneItemName,
              checkContents: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            }) 
          })
        },);
      }
    },
    addResult() {
      this.journal.results.splice(0, 0, {
        indHygieneJournalId: this.popupParam.indHygieneJournalId,
        hygieneJournalResultId: uid(),
        siteHygieneTypeCd: 'SHTC000006',
        hygieneItemName: '',
        checkContents: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeResult() {
      let selectData = this.$refs["grid"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.journal.deleteResults) this.journal.deleteResults = [];
          if (this.$_.findIndex(this.journal.deleteResults, {
            hygieneJournalResultId: item.hygieneJournalResultId,
              }) === -1 
            && item.editFlag !== "C"
          ) {
            this.journal.deleteResults.push(item);
          }
          this.journal.results = this.$_.reject(this.journal.results, item);
        });
      }
    },
    savePlan() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.hea.sih.check.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.hea.sih.check.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.journal.regUserId = this.$store.getters.user.userId
              this.journal.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.isOld) {
        this.$set(this.popupParam, 'indHygieneJournalId', _result.data)
      }
      this.getDetail();
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.indHygieneJournalId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange(data) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '점검항목이 변경됩니다.\n진행하시겠습니까?', // 점검항목이 변경됩니다.\n진행하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.journal.results = [];
          if(!data.value) {
            return;
          } else {
            this.$http.url = this.resultItemUrl;
            this.$http.type = 'GET';
            this.$http.param = {
              plantCd: data.value,
              useFlag: 'Y',
            };
            this.$http.request((_result) => {
              this.$_.forEach(_result.data, _item => {
                this.journal.results.push({
                  indHygieneJournalId: this.popupParam.indHygieneJournalId,
                  hygieneJournalResultId: uid(),
                  hygieneItemName: _item.hygieneItemName,
                  siteHygieneTypeCd: 'SHTC000006',
                  checkContents: '',
                  regUserId: this.$store.getters.user.userId,
                  editFlag: 'C',
                }) 
              })
            },);
          }
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approvalJournal() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.journal.regUserId = this.$store.getters.user.userId
              this.journal.chgUserId = this.$store.getters.user.userId

              this.isApproval = !this.isApproval
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    // [S] 결재관련 
    approvalJournalCallback(result) { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
  }
};
</script>
